import { HttpClient } from '@angular/common/http';
import { Injectable, inject, signal } from '@angular/core';
import { AuthService } from '@auth/auth.service';
import { RoleDetailDTO } from '@core/api-services/role-api/dto/role.dto';
import { IResponse } from '@shared/interfaces';
import { EMPTY, Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
const SUPER_ADMIN_ROLE_ID = 3;
@Injectable({
  providedIn: 'root',
})
export class RoleBaseAccessControlService {
  private readonly http = inject(HttpClient);
  private readonly BE_API_BASE_URL = environment.BE_API_BASE_URL;
  private readonly authService = inject(AuthService);
  private readonly permissionsLookUp = signal<Map<number, string>>(new Map());
  private _isSuperAdmin = signal<boolean>(false);
  public readonly isSuperAdmin = this._isSuperAdmin.asReadonly();

  fetchPermission(): Observable<IResponse<RoleDetailDTO[]>> {
    const type = this.authService.user()?.type;
    const userId = this.authService.user()?.id;

    if (type && userId) {
      return this.http
        .post<IResponse<RoleDetailDTO[]>>(
          `${this.BE_API_BASE_URL}/users/${userId}/roles/permissions`,
          {
            type,
          },
        )
        .pipe(
          tap((res) => {
            this.buildPermissionLookup(res.data);
          }),
        );
    } else {
      this._isSuperAdmin.set(false);
      return EMPTY;
    }
  }

  hasPermission(permissionIds: number) {
    if (this._isSuperAdmin()) {
      return true;
    }
    return this.permissionsLookUp().has(permissionIds);
  }

  hasSomePermission(permissionIds: number[]) {
    if (this._isSuperAdmin()) {
      return true;
    }
    return permissionIds.some((permissionId) =>
      this.hasPermission(permissionId),
    );
  }

  hasEveryPermission(permissionIds: number[]) {
    if (this._isSuperAdmin()) {
      return true;
    }
    return permissionIds.every((permissionId) =>
      this.hasPermission(permissionId),
    );
  }

  isCurrentUser(userId: number): boolean {
    return this.authService.user()?.id === userId;
  }

  private buildPermissionLookup(roles: RoleDetailDTO[]) {
    const newLookUp = new Map();
    roles.forEach((role) => {
      if (role.id === SUPER_ADMIN_ROLE_ID) {
        this._isSuperAdmin.set(true);
        return;
      }
      role.resources?.forEach((resource) => {
        role.dependentPermission?.forEach((depPermission) => {
          newLookUp.set(depPermission.dependentPermissionId, '');
        });
        resource.permissions?.forEach((permission) => {
          newLookUp.set(permission.id, permission.action);
        });
      });
    });
    this.permissionsLookUp.set(newLookUp);
  }
}
